import styled from 'styled-components'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { LazyImage } from 'components/atoms/Image'

import media from 'styles/media'

export const OverlayedGalleryWrapper = styled.section`
  position: relative;
`

export const OverlayedGallerySlider = styled(Slider)`
  position: relative;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    & > div {
      height: 100%;
    }
  }
`

export const OverlayedGallerySlide = styled.article`
  position: relative;
  display: flex;
`

export const OverlayedGalleryInner = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 64px 0 128px 0;
  width: 100%;
  height: calc(100vw * 0.55);
  max-height: 100vh;
  z-index: 2;

  ${media.lg.max} {
    padding: 32px 0;
  }
`

export const OverlayedGalleryBackground = styled(LazyImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.15);
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const OverlayedGalleryContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 640px;
  height: 100%;

  ${media.lg.max} {
    width: 100%;
    max-width: unset;
    align-items: flex-start;
  }
`

export const OverlayedGalleryTitle = styled.h2`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 52px;
  line-height: 60px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
  }
`
