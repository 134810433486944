import React from 'react'

import {
  Wrapper,
  Header,
  HeaderInner,
  HeaderText,
  Grid,
  GridItem,
  GirdItemInner,
  GridItemPhoto,
  GridItemTitle,
} from 'components/organisms/Gallery/GalleryGrid.style'
import Container from 'components/atoms/Container'

import generateKey from 'utils/generateKey'

import { IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  text?: string
  items: Array<{
    image: IGatsbyImageData
    text?: string
  }>
}

const GalleryGrid: React.FC<Props> = ({ text, items }) => {
  return (
    <Wrapper>
      {text && (
        <Header>
          <Container>
            <HeaderInner>
              <HeaderText dangerouslySetInnerHTML={{ __html: text }} />
            </HeaderInner>
          </Container>
        </Header>
      )}
      {items && (
        <Grid>
          {items.map((item, itemIndex) => (
            <GridItem key={generateKey(itemIndex)}>
              <GridItemPhoto
                src={item.image}
                alt={`Zdjęcie - Talaria ${item.text}`}
              />
              <GirdItemInner>
                {item.text && (
                  <GridItemTitle
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                )}
              </GirdItemInner>
            </GridItem>
          ))}
        </Grid>
      )}
    </Wrapper>
  )
}

export default GalleryGrid
