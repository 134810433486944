import React, { useState } from 'react'

import {
  Wrapper,
  Inner,
  Header,
  NavigationTabs,
  NavigationTab,
  Content,
  Products,
  Product,
  ProductHeader,
  ProductText,
  ProductContent,
  ProductGallery,
  ProductPhoto,
  ProductFooter,
  ProductPrice,
  ProductContact,
  ProductSpecification,
  ProductSpecificationHeader,
  ProductSpecificationTitle,
  ProductSpecificationContent,
} from 'components/organisms/Offer/Offer.style'
import Container from 'components/atoms/Container'
import Accordion from 'components/modules/Accordion'
import generateKey from 'utils/generateKey'

import { IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  products?: Array<{
    slug: string
    title: string
    text?: string
    gallery?: Array<{
      image: IGatsbyImageData
    }>
    price?: string
    specification?: {
      tabs: Array<{
        title: string
        list: Array<{
          label: string
          value: string
        }>
      }>
    }
  }>
  displayModalContact: () => void
}

const Offer: React.FC<Props> = ({ products, displayModalContact }) => {
  const [productActive, setProductActive] = useState(
    products?.[0]?.slug ?? null
  )

  return (
    <Wrapper>
      <Container>
        <Inner>
          <Header>
            <NavigationTabs>
              {products?.map((product, productIndex) => (
                <NavigationTab
                  type="button"
                  active={product.slug === productActive}
                  onClick={() => setProductActive(product?.slug)}
                  key={generateKey(productIndex)}
                >
                  {product?.title}
                </NavigationTab>
              ))}
            </NavigationTabs>
          </Header>
          <Content>
            <Products>
              {products?.map((product, productIndex) =>
                product.slug === productActive ? (
                  <Product key={generateKey(productIndex)}>
                    {product?.text && (
                      <ProductHeader>
                        <ProductText
                          dangerouslySetInnerHTML={{ __html: product?.text }}
                        />
                      </ProductHeader>
                    )}
                    <ProductContent>
                      <ProductGallery
                        arrows={false}
                        dots
                        slidesToShow={1}
                        slidesToScroll={1}
                        fade
                      >
                        {product?.gallery?.map((photo, photoIndex) => (
                          <ProductPhoto
                            src={photo?.image!}
                            alt=""
                            key={generateKey(photoIndex)}
                            objectFit="contain"
                          />
                        ))}
                      </ProductGallery>
                    </ProductContent>
                    <ProductFooter>
                      {product?.price && (
                        <ProductPrice
                          dangerouslySetInnerHTML={{
                            __html: `Cena od <strong>${product.price} zł</strong>`,
                          }}
                        />
                      )}
                      <ProductContact onClick={displayModalContact}>
                        Umów się na jazdę próbną
                      </ProductContact>
                    </ProductFooter>
                    {product.specification && (
                      <ProductSpecification>
                        <ProductSpecificationHeader>
                          <ProductSpecificationTitle>
                            Specyfikacja
                          </ProductSpecificationTitle>
                        </ProductSpecificationHeader>
                        <ProductSpecificationContent>
                          <Accordion tabs={product.specification?.tabs} />
                        </ProductSpecificationContent>
                      </ProductSpecification>
                    )}
                  </Product>
                ) : (
                  false
                )
              )}
            </Products>
          </Content>
        </Inner>
      </Container>
    </Wrapper>
  )
}

export default Offer
