import styled from 'styled-components'
import { LazyImage } from 'components/atoms/Image'
import media from 'styles/media'

export const Wrapper = styled.section`
  padding: 112px 0;

  ${media.lg.max} {
    padding: 64px 0 0 0;
  }
`

export const Inner = styled.div``

export const Header = styled.div``

export const HeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeaderText = styled.div`
  margin: 0 0 72px 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ${media.lg.max} {
    margin: 0 0 48px 0;
    font-size: 18px;
    line-height: 26px;
  }
`

export const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 32px;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;

  ${media.lg.max} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 16px;
  }
`

export const GridItem = styled.article`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(6deg, #000000 5%, rgba(0, 0, 0, 0) 80%);
    z-index: 2;
  }

  ${media.lg.max} {
    height: 80vw;
    max-height: 600px;
  }

  ${media.lg.min} {
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }

    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }

    &:nth-child(3) {
      grid-area: 1 / 2 / 3 / 3;
    }

    &:nth-child(4) {
      grid-area: 3 / 1 / 4 / 3;
    }
  }
`

export const GirdItemInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 17.5vw 48px 48px 48px;
  z-index: 3;

  ${media.lg.max} {
    padding: 32px;
  }
`

export const GridItemPhoto = styled(LazyImage)`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const GridItemTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  text-align: left;

  ${media.lg.max} {
    font-size: 18px;
    line-height: 26px;
  }
`
