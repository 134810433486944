import React from 'react'

import {
  OverlayedGalleryWrapper,
  OverlayedGallerySlider,
  OverlayedGallerySlide,
  OverlayedGalleryBackground,
  OverlayedGalleryInner,
  OverlayedGalleryContent,
  OverlayedGalleryTitle,
} from 'components/organisms/OverlayedPhoto/OverlayedGallery.style'
import Container from 'components/atoms/Container'

import generateKey from 'utils/generateKey'

import { IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  id?: string
  slides: Array<{
    title: string
    background: IGatsbyImageData
  }>
}

const OverlayedGallery: React.FC<Props> = ({ id, slides }) => {
  return (
    <OverlayedGalleryWrapper id={id}>
      <OverlayedGallerySlider
        arrows={false}
        autoplay
        autoplaySpeed={3000}
        dots={false}
        slidesToShow={1}
        slidesToScroll={1}
        fade
      >
        {slides.map((slide, slideIndex) => (
          <OverlayedGallerySlide key={generateKey(slideIndex)}>
            <OverlayedGalleryBackground src={slide.background} alt="" />
            <Container>
              <OverlayedGalleryInner>
                <OverlayedGalleryContent>
                  {slide.title && (
                    <OverlayedGalleryTitle
                      dangerouslySetInnerHTML={{
                        __html: slide.title,
                      }}
                    />
                  )}
                </OverlayedGalleryContent>
              </OverlayedGalleryInner>
            </Container>
          </OverlayedGallerySlide>
        ))}
      </OverlayedGallerySlider>
    </OverlayedGalleryWrapper>
  )
}

export default OverlayedGallery
