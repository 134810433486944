import styled, { css } from 'styled-components'

import media from 'styles/media'

import imageEmblem from 'assets/images/talaria-emblemat.svg'
import iconClose from 'assets/icons/close.svg'

export const ModalOverlay = styled.div<{
  active: boolean
}>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transition-property: opacity, visibility;
  opacity: 0;
  visibility: hidden;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const ModalContent = styled.div<{
  active: boolean
}>`
  position: relative;
  width: 100%;
  margin: 16px;
  max-width: 700px;
  padding: 64px 48px;
  max-height: calc(100vh - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black};
  background-image: url(${imageEmblem});
  background-size: auto 400px;
  background-repeat: no-repeat;
  background-position: center 160px;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 60px);
  transition: 0.35s ease;

  ${media.lg.max} {
    padding: 48px 32px;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0) !important;
    `}
`

export const ModalClose = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  margin: 0;
  padding: 0;
  height: 40px;
  width: 40px;
  font-size: 0;
  line-height: 0;
  border: 0;
  background-color: transparent;
  background-image: url(${iconClose});
  background-size: cover;
  opacity: 1;
  z-index: 5;
  cursor: pointer;
  transition: opacity 300ms ease-in-out;

  img {
    transition: 0.2s;
  }
  &:hover {
    opacity: 0.72;
  }
`

export const ModalInner = styled.div`
  position: relative;
  max-height: calc(100vh - 128px);
  overflow-y: auto;
  padding: 0 16px 0 0;
  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(0, 0, 0);
    border: 4px solid transparent;
    background-clip: content-box; /* THIS IS IMPORTANT */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(25, 25, 25);
    border: 1px solid rgb(0, 0, 0);
  }

  ${media.lg.max} {
    max-height: calc(100vh - 96px);
  }
`
