import React from 'react'

import {
  BannerWrapper,
  BannerVideo,
  BannerInner,
  BannerTitle,
} from 'components/organisms/Banner/BannerMain.style'
import Container from 'components/atoms/Container'

type Props = {
  title: string
  video: string
}

const BannerMain: React.FC<Props> = ({ title, video }) => {
  return (
    <BannerWrapper>
      <BannerVideo loop muted autoPlay playsInline>
        <source src={video} type="video/mp4" />
      </BannerVideo>
      <Container>
        <BannerInner>
          <BannerTitle>{title}</BannerTitle>
        </BannerInner>
      </Container>
    </BannerWrapper>
  )
}

export default BannerMain
