import React from 'react'

import {
  AccordionWrapper,
  AccordionTab,
  AccordionList,
  AccordionListItem,
  AccordionListLabel,
  AccordionListValue,
} from 'components/modules/Accordion/Accordion.style'

type Props = {
  tabs: Array<{
    title: string
    list: Array<{
      label: string
      value: string
    }>
  }>
}

const Accordion: React.FC<Props> = ({ tabs }) => {
  return (
    <AccordionWrapper transition transitionTimeout={200}>
      {tabs.map((tab, tabIndex) => {
        return (
          <AccordionTab header={tab.title} initialEntered={tabIndex === 0}>
            {tab.list && (
              <AccordionList>
                {tab.list.map((item) => (
                  <AccordionListItem>
                    <AccordionListLabel>{item.label}</AccordionListLabel>
                    <AccordionListValue
                      dangerouslySetInnerHTML={{ __html: item.value }}
                    />
                  </AccordionListItem>
                ))}
              </AccordionList>
            )}
          </AccordionTab>
        )
      })}
    </AccordionWrapper>
  )
}

export default Accordion
