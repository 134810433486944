import styled from 'styled-components'
import { LazyImage } from 'components/atoms/Image'

import media from 'styles/media'

import imageLine1 from 'assets/images/line-1.svg'

export const BannerInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  z-index: 2;

  ${media.lg.max} {
    padding: 0 48px;
  }
`

export const BannerTitle = styled.h1`
  position: relative;
  font-size: 120px;
  line-height: 150px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    left: -460px;
    bottom: -95px;
    width: 855px;
    height: 116px;
    background-image: url(${imageLine1});
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;
    z-index: 3;

    ${media.lg.max} {
      left: -280px;
      bottom: -80px;
      width: 500px;
      height: 80px;
    }
  }

  ${media.lg.max} {
    font-size: 50px;
    line-height: 58px;
  }
`

export const BannerWrapper = styled.section`
  position: relative;
  height: calc(100vh - 105px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      #000 0%,
      rgba(0, 0, 0, 0.1) 20%,
      rgba(0, 0, 0, 0.1) 80%,
      #000 100%
    );
    z-index: 2;
  }
`

export const BannerPlaceholder = styled(LazyImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const BannerVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
`
