import React, { useRef } from 'react'

import {
  ModalOverlay,
  ModalContent,
  ModalClose,
  ModalInner,
} from 'components/organisms/ContactModal/ContactModal.style'
import ContactForm from 'components/modules/ContactForm'

import useOutsideClick from 'hooks/useOutsideClick'

type Props = {
  id?: string
  active: boolean
  onClose: () => void
}

const ContactModal: React.FC<Props> = ({ id, active, onClose }) => {
  const modalRef = useRef(null)

  const closeModal = () => {
    onClose()
  }

  useOutsideClick({
    ref: modalRef,
    handler: closeModal,
    condition: active,
  })

  return (
    <ModalOverlay active={active}>
      <ModalContent active={active} ref={modalRef}>
        <ModalClose onClick={closeModal} type="button" />
        <ModalInner>
          <ContactForm
            id={id}
            title="Umów się na jazdę próbną"
            titleAlignCenter
          />
        </ModalInner>
      </ModalContent>
    </ModalOverlay>
  )
}

export default ContactModal
